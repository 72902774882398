.category {
  $category-background: #fff;
  &-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 90;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    .lt-ie11 & {
      display: none;
    }
    &:after {
      border-radius: 50%;
      background-color: $category-background;
      position: absolute;
      display: block;
      right: (85px/3);
      top: (85px/3);
      @include responsive(tablet) {
        top: 65px;
        right: 55px;
      }
      z-index: 90;
      width: 400%;
      width: 400vw;
      width: 400vmax;
      height: 400%;
      height: 400vw;
      height: 400vmax;
      @include transition(1.5s);
      transform: translate(50%,-50%) scale(0,0);
      will-change: transform;
      content: '';
    }
    &--active {
      pointer-events: auto;
      .lt-ie11 & {
        display: block;
      }
    }
    &--animate {
      will-change: transform;
    }
    &--active:after {
      transform: translate(50%,-50%) scale(1,1);
    }
    .category-body {
      opacity: 0;
      z-index: 100;
      position: relative;
      transition-delay: 750ms;
      height: 100%;
      overflow-y: scroll;
    }
    &--active .category-body {
      opacity: 1;
    }
    .category__item {
      opacity: 0;
      transform: translateY(-100px);
      transition: all 500ms;
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: #{500 + ($i * 300)}ms;
        }
      }
    }
    &--active .category__item {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &-body {
    @include transition(.75s);
  }
  background-color: $category-background;
  color: #34343a;
  &__container {
    padding: (235px/3) (70px/3) (135px/3);
    max-width: 1220px;
    @include responsive(tablet) {
      padding: 170px 0 90px 0;
      width: 68%;
      margin: 0 auto;
    }
    @include responsive(wide) {
      padding: 200px 0 150px;
      width: 90%;
    }
  }
  &__menu {
    margin: -20px 0;
    @include responsive(tablet) {
      margin: -45px 0;
    }
    @include responsive(wide) {
      margin: percentage(-75px/1220px) percentage(-85px/1220px);
      font-size: 0;
    }
  }
  &__item {
    counter-increment: category-item;
    position: relative;
    padding-left: (60px/3);
    margin: 20px 0;
    display: inline-block;
    width: 100%;
    @include responsive(tablet) {
      margin: 45px 0;
      padding-left: 35px;
    }
    @include responsive(wide) {
      width: percentage(525px / 1220px);
      display: inline-block;
      vertical-align: top;
      margin: percentage(75px/1220px) 3.3%;
    }

    &:before {
      content: '0'counter(category-item);
      font-family: $roboto-font;
      font-size: (30px/3);
      position: absolute;
      transform: rotate(-90deg);
      left: 0;
      font-weight: bold;
      top: (30px/3);
      @include responsive(tablet) {
        font-size: 20px;
      }
    }

    /*&:hover &-title,
    &:active &-title {
      &:after,
      &-value:after {
        width: 90%;
      }
    }*/
    &:hover &-title-sub {
        width: 100%;
        @include transition(300ms);
    }

    &-title {
      font-family: $maven-font;
      letter-spacing: .05em;
      font-size: (130px/3);
      @include transition(750ms);
      position: relative;
      @include responsive(tablet) {
        font-size: 60px;
      }

      /*&:after {
        display: block;
        content: '';
        width: 0;
        height: 1px;
        top: 50%;
        z-index: 0;
        margin-top: 5px;
        background-color: #34343a;
        position: absolute;
        @include transition(750ms);
        left: 0;
      }*/
      &-value {
        transition: all 300ms;
        position: relative;
        display: inline-block;
        background-color: $category-background;
        /*padding-right: 25px;*/
        width: 90%;
        z-index: 3;
        /*&:after {
          content: attr(title);
          transition: all 300ms;
          display: block;
          position: absolute;
          @include transition(300ms);
          left: 0;
          top: 0;
          -webkit-text-stroke: 1px $red-color;
          text-shadow: 0 0 1px $red-color;
          color: $red-color;
          width: 0;
          overflow: hidden;
        }*/
      }
      &-sub {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffff;
        @include transition(300ms);
        transition-delay: 100ms;
        overflow: hidden;
        &--title {
          position: absolute;
          display: inline-block;
          padding-right: 25px;
          color: $red-color;
          background-color: #ffffff;
          overflow: hidden;
          z-index: 2;
        }
        &--line {
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 1px;
          right: 0;
          top: 0;
          margin: 32px 0 0;
          background-color: #34343a;
          vertical-align: middle;
          overflow: hidden;
          z-index: 1;
          border-radius: 1px 1px 1px 1px;
          @include responsive(tablet) {
            margin: 35px 0 0;
          }
        }
      }


    }
    &-content {
      color: #525258;
      font-family: $opensans-font;
      line-height: (54 / 33);
      font-size: (33px/3);
      margin-top: (20px/3);
      @include responsive(tablet) {
        font-size: 18px;
        margin-top: 20px;
      }
    }

  }
}