
/*
 * MW.Layer Class
 */
.mw-layer {
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height:100%;
  z-index: 9999;
  display: table;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.5);
  table-layout: fixed;
}
.mw-layer-content {
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
}
.mw-layer-content iframe {
  width: 100%;
  height: 100%;
}


.mw-layer-container {
  position:relative;
  background-color: #fff;
  max-width: 83%;
  margin:0 auto;
  border: 1px solid #cccccc;
}
.mw-layer-scroller {
  display: table-cell;
  vertical-align: middle;
}
.mw-layer-scroller:before,
.mw-layer-scroller:after{
  clear:both;
  display:block;
  content:"";
  width:100%;
  height:91px;
}
.mw-layer-close-button {
  width: 14px;
  height: 14px;
  text-indent: -9999px;
  position: absolute;
  right: (43px/3);
  top: (62px/3);
  display: block;
  cursor: pointer;
  border: none;
  overflow: hidden;
  border-radius: 3px;
  @include responsive(tablet) {
    width: 23px;
    height: 23px;
    right: 20px;
    top: 30px;
  }
  &:before,
  &:after {
    width: 70px;
    height: 1px;
    background-color: #555555;
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
.mw-layer-close-area{
  cursor:pointer;
}

.layer-content {
  padding: 20px (40px/3) 33px;
  @include responsive(tablet) {
    padding: 30px 20px 40px;
  }
  .contact-form__checkbox {
    font-size: 12px;
    $color: #525258;
    color: $color;
    @include responsive(tablet) {
      font-size: 16px;
    }
    &-shape {
      border-color: $color;
      &:before {
        background-color: #ffffff;
      }
      &:after {
        border-color: $color;
      }
    }
  }
  &__header,
  &__footer {
    padding: 0 (10px/3);
    @include responsive(tablet) {
      padding: 0 5px;
    }
  }
  &__header {
    margin-bottom: 20px;
  }
  &__footer {
    margin-top: 13px;
    @include responsive(tablet) {
      margin-top: 20px;
    }
  }
  &__title {
    font-size: 13px;
    letter-spacing: -0.025em;
    font-weight: bold;
    @include responsive(tablet) {
      font-size: 20px;
    }
  }
  &__container {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    padding: 10px 13.3px;
    @include responsive(tablet) {
      padding: 15px 20px;
    }
  }
  &__scroller {
    max-height: 250px;
    overflow-y: auto;
    font-size: 10px;
    line-height: (50/30);
    letter-spacing: -0.025em;
    color: #525258;
    @include responsive(tablet) {
      font-size: 13px;
    }
  }



}