.visual {
  &-nav {
    position: absolute;
    width: 100%;
    bottom: 10px;
    z-index: 60;
    @include responsive(tablet) {
      bottom: 30px;
    }
    @include responsive(desktop) {
      bottom: 50px;
    }
    @include responsive(wide) {
      position: static;
    }
    &__menu {
      @include responsive(wide) {
        position: absolute;
        width: auto;
        bottom: auto;
        top: 50%;
        left: 50%;
        margin-left: -445px;
        margin-top: 110px;
        z-index: 10;
      }
    }
    &__wrapper {
      display: table;
      margin: 0 auto;
    }
    &__button {
      display: block;
      color: #656565;
      [data-style="red"] & {
        color: #fff;
      }
      font-family: $maven-font;
      font-size: 10px;
      letter-spacing: .025em;
      position: absolute;
      bottom: 7px;
      z-index: 10;
      @include responsive(tablet) {
        font-size: 16px;
      }
      &-value {
        vertical-align: middle;
      }
      &--prev {
        left: 15px;
        @include responsive(tablet) {
          left: 40px;
        }
        @include responsive(desktop) {
          left: 60px;
        }
      }
      &--next {
        right: 15px;
        @include responsive(tablet) {
          right: 40px;
        }
        @include responsive(desktop) {
          right: 60px;
        }
      }
      &:before,
      &:after {
        display: inline-block;
        content: '';
        background-image: url('/images/arrow.png');
        background-image: none, url('/images/arrow.svg');
        [data-style="red"] & {
          content:'x';
          background-image: url('/images/arrow-white.png');
          background-image: none, url('/images/arrow-white.svg');
        }
        font-size: 0;
        background-size: 100% 100%;
        width: 25px;
        height: 7px;
        vertical-align: middle;
        margin-left: (20px/3);
        @include responsive(tablet) {
          width: 72px;
          height: 16px;
        }
      }
      &:before {
        display: none;
        margin-right: (20px/3);
        margin-left: 0;
      }
      &--prev {
        &:before {
          display: inline-block;
          content: '';
          transform: scaleX(-1);
        }
        &:after {
          display: none;
        }
      }
      @include responsive(wide) {
        bottom: auto;
        top: 50%;
        margin-top: -8px;
        z-index: 15;
        &--prev,
        &--next:after,
        &--next {
          transform: none;
        }
        &--prev {
          left: 40px;
          &:before {
            display: inline-block;
            transform: scaleX(-1);
          }
          &:after {
            display: none;
          }
        }
        &--next {
          right: 40px;
        }
      }
    }
    &__item {
      float: left;
      &-anchor {
        display: block;
        width: (62px/3);
        height: (62px/3);
        @include responsive(tablet) {
          width: 35px;
          height: 35px;
        }
        @include responsive(only-desktop) {
          width: 68px;
        }
        position: relative;
        text-indent: -9999px;
        &:after {
          width: 4px;
          height: 4px;
          background-color: #656565;
          [data-style="red"] & {
            background-color: #ffffff;
            content:'x';
          }
          opacity: .5;
          transition: all 300ms;
          border-radius: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          @include responsive(tablet) {
            width: 5px;
            height: 5px;
          }
          @include responsive(only-desktop) {
            width: 8px;
            height: 8px;
          }
          display: block;
          content: '';
          font-size: 0;
        }
        &--active:after {
          opacity: 1;
          content: 'x';
        }
      }
    }
  }
}