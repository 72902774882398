@import "../setting";
html,body {
}
body {
  transition: all 500ms;
  //border-top: rem(10) solid $red-color;
  //border-bottom: rem(10) solid $red-color;
  //@include responsive(desktop) {
  //  border-width: 10px;
  //}
  &:before,
  &:after {
    width: 100%;
    height: 100%;
    height: 100vh;
    display: block;
    font-size: 0;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: background-image 500ms, border-color 500ms;
    .lt-ie11 & {
      display: none;
    }
  }
  &.portfolio:before {
    border-color: currentColor;
  }
  &:before {
    display: block;
    border: 3px solid $red-color;
    @include responsive(tablet) {
      border-width: 10px;
    }
    z-index: 68;
  }
  &[data-style="white"] {
    /*background-color: #f9f9f9;*/
    background-color: #fff;
  }
  &[data-style="red"] {
    background-color: $red-color;
  }
  &[data-style="red"]:after {
    content: 'x';
    /*background-image: repeating-linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.075),
                    rgba(255, 255, 255, 0.075) 1px,
                    rgba(255, 255, 255, 0) 2px,
                    rgba(255, 255, 255, 0) 100%
    );*/
  }
  &:after {
    z-index: 65;
    /*background-image: repeating-linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0.03),
                    rgba(0, 0, 0, 0.03) 1px,
                    rgba(255, 255, 255, 0) 2px,
                    rgba(255, 255, 255, 0) 100%
    );*/

    @include responsive(tablet) {
      background-size: (100% / 6) auto;
    }
    @include responsive(wide) {
      background-size: (100% / 11) auto;
    }
  }
}
.body-wrapper {
  position: relative;
  z-index: 60;
  height: 100%;
  width: 100%;
  padding: 3px;
  @include responsive(tablet) {
    padding: 10px;
  }
  .lt-ie11 & {
    padding: 0;
  }
}
.layout-page {
  position: relative;
  height: 100%;
  width: 100%;
}