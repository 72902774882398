.button {
  display: block;
  position: relative;

  text-align: center;
  border: 1px solid currentColor;
  font-family: $maven-font;
  font-size: 11px;

  width: 127px;
  height: 35px;
  line-height: 35px - 2px;

  @include responsive(tablet) {
    width: 175px;
    font-size: 15px;
    height: 48px;
    line-height: 48px - 2px;
  }
  letter-spacing: 0.01em;
  transition: border 300ms;
  color: #34343a;
  &-value {
    position: relative;
    z-index: 5;
    transition: all 300ms;
  }
  &:hover &-value {
    color: #fff;
  }
  &:hover:after {
    width: 100%;
  }
  &:after {
    background-color: currentColor;
    transition: all 300ms;
    width: 0%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  [data-style="red"] & {
    color: #ffffff;
    &:hover .button-value {
      color: #34343a;
    }
  }
}