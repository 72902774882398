.about {
  // Top
  &-top {
    position: fixed;
    background-image: url(../images/btn-top.svg);
    background-size: 100% 100%;
    z-index: 10;
    display: block;
    text-indent: -9999px;
    right: (40px/3);
    bottom: (40px/3);
    width: (100px/3);
    height: (100px/3);
    @include responsive(tablet) {
      right: 40px;
      bottom: 40px;
      width: 40px;
      height: 40px;
    }
  }
  // Section
  &-section {
    position: relative;
    overflow: hidden;
    padding: (150px/3) 0;
    @include responsive(tablet) {
      padding: 100px 0;
    }
    @include responsive(desktop) {
      padding: 120px 0;
    }
    @include responsive(wide) {
      font-size: 0;
      text-align: center;
      padding: 100px 0;
    }
    &__header {
      padding: 0 (69px/3);
      margin-bottom: (100px/3);
      position: relative;
      @include responsive(wide) {
        &-wrapper {
          transition: opacity 300ms;
          &--fixed {
            position: fixed;
            width: 100%;
            top: 90px;
          }
        }
      }
      @include responsive(tablet) {
        padding: 0;
        width: 84%;
        margin-left: auto;
        margin-bottom: 40px;
      }
      @include responsive(wide) {
        width: 50%;
        display: inline-block;
        padding: 0 50px 0 0;
        max-width: 838px;
        vertical-align: top;
        text-align: left;
      }
      @include responsive(wide) {
        padding-left: 50px;
      }
    }
    &__content {
      padding: 0 (69px/3);
			word-break: keep-all;
			word-wrap: break-word;
      @include responsive(tablet) {
        width: 84%;
        padding: 0 50px 0 0;
        margin-left: auto;
      }
      @include responsive(desktop) {
        padding: 0 160px 0 0;
      }
      @include responsive(wide) {
        display: inline-block;
        width: 50%;
        padding: 0 0 0 50px;
        max-width: 838px;
        vertical-align: top;
        text-align: left;
      }
      @include responsive(wide) {
        padding-left: 88px;
        padding-right: 50px;
      }
    }
    &__description {
      font-size: (36px/3);
      line-height: (32 / 18);
      color: #525258;
      letter-spacing: -0.025em;
      margin-top: (75px/3);
			word-break: keep-all;
			word-wrap: break-word;
      @include responsive(tablet) {
        padding-right: (150px/3);
        font-size: 18px;
        margin-top: 40px;
      }
      @include responsive(desktop) {
        padding-right: 155px;
      }
      @include responsive(wide) {
        padding-right: 0;
        font-size: 18px;
      }
    }
    &__title {
      font-family: $maven-font;
      font-size: (110px/3);
      letter-spacing: -0.025em;
      position: relative;
      white-space: nowrap;
      @include responsive(tablet) {
        font-size: 85px;
      }
      @include responsive(wide) {
        font-size: 90px;
        white-space: normal;
      }
    }

  }
  // intro
  &-intro {
		@media all and (min-width: 1676px) {
			position: relative;
		}
    .about-section__header {
			position: relative;
			padding: 0;
			margin-top: -6%;
			margin-bottom: (100px/3);
			@include responsive(tablet) {
				width: auto;
				margin-bottom: 102px;
			}
			@include responsive(desktop) {
				margin-top: 0;
			}
			@include responsive(wide) {
				width: 50%;
			}
			@media all and (min-width: 1676px) {
				position: static;
			}
    }
    .about-section__title {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 0 23px;
      width: 100%;
			height: 69.3px;
      @include responsive(tablet) {
        width: 84%;
        height: 161px;
        left: auto;
        padding: 0 50px 0 0;
      }
      @include responsive(desktop) {
        padding: 0 160px 0 0;
      }
      @include responsive(wide) {
				width: 100%;
				margin: auto;
				padding: 0 50px;
      }
			@media all and (min-width: 1676px) {
				position: static;
				padding-top: 260px;
				height: auto;
			}
    }

    &__footer {
      display: none;
      margin-top: 75px;
      margin-bottom: -70px;
      @include responsive(wide) {
        display: block;
      }
    }

    &__introduce {
      font-size: (42px/3);
      letter-spacing: -0.075em;
      line-height: (66 / 42);
      @include responsive(tablet) {
        font-size: 26px;
        line-height: 1.6;
      }
      @include responsive(desktop) {
        font-size: 26px;
        line-height: (44 / 26);
      }
      @include responsive(wide) {
        padding-top: 260px;
      }
    }

    // Data Rank
    &-data {
      margin-top: (130px/3);
      @include clearfix;
      @include responsive(tablet) {
        margin-top: 80px;
      }
      @include responsive(desktop) {
        margin-top: 150px;
      }
			&__list {
				font-size: 0;
				white-space: nowrap;
				@include clearfix;
			}
      &__item {
				display: inline-block;
        & ~ & {
          margin-left: (85px/3);
          @include responsive(tablet) {
            margin-left: 90px;
          }
          @include responsive(wide) {
            margin-left: 115px;
          }
        }
        &-title {
          font-size: (30px/3);
          font-family: $maven-font;
          font-weight: bold;
          letter-spacing: 0.01em;
          @include responsive(tablet) {
            font-size: 15px;
          }
        }
        &-value {
          margin-top: (30px/3);
          font-family: $roboto-font;
          font-size: (120px/3);
          color: #525258;
          font-weight: bold;
          @include responsive(tablet) {
            font-size: 80px;
            margin-top: 22px;
          }
          @include responsive(desktop) {
            font-size: 90px;
          }
        }
      }
    }

    &__title {
      position: relative;
      z-index: 3;
    }

    &__logo {
      display: block;
      font-family: $maven-font;
      font-weight: 800;
      font-size: (500px/3);
      line-height: (400 / 500);
      letter-spacing: -0.06em;
      opacity: .1;
      left: 0;
      top: 0;
	    margin-left: -4%;
      color: #222222;
      &:after {
        content: '_';
        margin-left: (60px/3);
        font-size: .5em;
        position: relative;
        top: (-60px/3);
        animation: visual-letter-typing 500ms linear infinite alternate;
        @include responsive(tablet) {
          margin-left: 35px;
        }
      }
      @include responsive(tablet) {
        font-size: 400px;
        margin-left: -4.5%;
      }
      @include responsive(desktop) {
        /*margin-left: -100px;
        margin-left: (-100/1024*100)+vw;*/
      }
      @include responsive(wide) {
        font-size: 300px;
        font-size: 26vw;
      }
			@media all and (min-width: 1676px) {
				position: absolute;
				left: 0;
				top: 0;
				margin-top: 2%;
				margin-left: -2.5%;
			}
      @media all and (min-width:1920px) {
        font-size: 500px;
      }
    }
  }

  // Us\
  &-us {
    background-color: #fafafa;

    // Description
    &-description {
      font-size: 11px;
      letter-spacing: -0.05em;
      line-height: (30/16);
      white-space: nowrap;
      &__title {
        font-weight: bold;
      }
      @include responsive(tablet) {
        font-size: 16px;
      }
      &__btn {
        &-wrap {
          display: flex;
          margin-top: 25px;
          & .button:first-child {
            margin-right: 15px;
          }
        }
      }
    }

    &-certification {
      @include responsive(tablet) {
        padding-right: 0;
      }
      &__wrapper {
        margin: -10px -9px;
        @include responsive(tablet) {
          margin: -25px -95px;
        }
        @include responsive(desktop) {
          margin: -25px -42px;
        }
        @include responsive(wide) {
          margin: -25px 0;
        }
        font-size: 0px;
      }
      &__image {
        display: block;
        margin-bottom: 8px;
      }
      &__item {
        width: 50%;
        padding: 10px 9px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 2;
        @include responsive(tablet) {
          padding: 25px 95px;
        }
        @include responsive(desktop) {
          padding: 25px (120px + 42px) 25px 42px;

        }
        @include responsive(wide) {
          padding: 25px 190px 25px 0;
        }
      }
    }
    &__item {
      & ~ & {
        margin-top: (90px/3);
        @include responsive(tablet) {
          margin-top: 60px;
        }
        @include responsive(desktop) {
          margin-top: 75px;
        }
      }
      &-title {
        font-family: $opensans-font;
        font-size: (39px/3);
        letter-spacing: -0.05em;
        margin-bottom: (50px/3);
        display: block;
        font-weight: bold;
        @include responsive(tablet) {
          font-size: 20px;
          margin-bottom: 25px;
        }
      }
      &-content {
        font-size: (33px/3);
        letter-spacing: -0.05em;
        line-height: (30 / 16);
        @include responsive(tablet) {
          font-size: 16px;
        }
      }
      &-logo {
        &-description,
        &-image {
          display: block;
        }
        &-image {
          width: 48%;
          &.dpromotion {
            width: 100%;
          }
          @include responsive(tablet) {
            width: 70%;
          }
          @include responsive(desktop) {
            width: auto;
          }
          & + .about-us-description {
            margin-top: 25px;
          }
        }
        &-description ~ &-image,
        &-description ~ .button,
        &-image ~ &-description {
          margin-top: (42px/3);
          @include responsive(tablet) {
            margin-top: 25px;
          }
        }
      }
    }
  }
  // What
  &-what {
    padding-bottom: 0;
    @include responsive(wide) {
      text-align: right;
    }
    .about-section__header {
      @include responsive(only-mobile) {
        margin-bottom: (70px/3);
      }
    }
    .about-section__content {
      padding: 0 0 0 (70px/3);
      @include responsive(tablet) {
        padding: 0;
      }
      @include responsive(desktop) {
      }
      @include responsive(wide) {
        max-width: 50%;
				padding-left: 88px;
      }
    }
    &__subject {
      font-size: 20px;
      letter-spacing: -0.01em;
      line-height: 1.5;
      & ~ & {
        margin-top: (90px/3);
        @include responsive(tablet) {
          margin-top: 50px;
        }
      }
      &-title {
        font-family: $opensans-font;
        font-weight: 600;
        letter-spacing: 0.025em;
        font-size: (39px/3);
        display: block;
        margin-bottom: (45px/3);
        @include responsive(tablet) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      &-content {
        font-size: (33px/3);
        letter-spacing: -0.01em;
        line-height: (56 / 33);
        @include responsive(tablet) {
          font-size: 16px;
        }
      }
    }
    &__description {
      & ~ .about-what__list {
        margin-top: (30px/3);
        @include responsive(tablet) {
          margin-top: 17px;
        }
      }
    }
    &__list {
      &-item {
        padding-left: (18px/3);
        position: relative;
        @include responsive(tablet) {
          padding-left: 9px;
        }
        &:before {
          content: '\00B7';
          position: absolute;
          left: 0;
        }
      }
    }
    &__item {
      & ~ & {
        margin-top: -1px;
      }
      &--active &-content {
        display: block;
      }
      &--active &-title {
        // Active
        border-color: $red-color;
        //background-color: $red-color;
        color: #ffffff;
        padding-left: (39px/3);
        &:after {
          width: 100%;
          opacity: 1;
        }
        @include responsive(tablet) {
          padding-left: 30px;
        }
        &-plus:after,
        &-plus:before {
          background-color: #ffffff;
          transform: rotate(360deg);
        }
        &-plus:after {
          opacity: 0;
        }
      }
      &-title {
        cursor: pointer;
        font-family: $opensans-font;
        font-size: (45px/3);
        letter-spacing: 0.01em;
        font-weight: 400;
        padding: (60px/3) 0;
        display: block;
        position: relative;
        &:after {
          background-color: $red-color;
          width: 0;
          height: 100%;
          @include transition(300ms);
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 0;
        }
        @include responsive(tablet) {
          padding: 42px 0;
          font-size: 28px;
        }
        border-top: (3px/3) solid #ccc;
        border-bottom: (3px/3) solid #ccc;
        @include transition(300ms);
        &-value {
          position: relative;
          z-index: 5;
        }
        &-plus {
          display: block;
          position: absolute;
          z-index: 5;
          width: (40px/3);
          height: (3px/3);
          right: (69px/3);
          top: 50%;
          @include transition(300ms);
          @include responsive(tablet) {
            width: 20px;
            height: 2px;
            right: 30px;
            margin-top: -1px;
          }
          &:after,
          &:before {
            display: block;
            content: '';
            position: absolute;
            background-color: #656565;
            width: 100%;
            height: 1px;
            @include transition(300ms);
            @include responsive(tablet) {
              height: 2px;
            }
          }
          &:after {
            transform: rotate(90deg);
          }
        }
      }
      &-content {
        padding: (115px/3) (70px/3) (115px/3) 0;
        border-bottom: (3px/3) solid #ccc;
        display: none;
        @include responsive(desktop) {
          padding: 55px 55px 55px 0;
          border-bottom-width: 1px;
        }
      }
    }
  }
  &-workspace {
    padding: (200px/3) 0 0 0;
    @include responsive(tablet) {
      padding: 150px 0 0 0;
    }
    @include responsive(wide) {
      padding: 100px 0;
      text-align: right;
    }
    .about-section__header {
      margin-bottom: (60px/3);
      @include responsive(tablet) {
        margin-bottom: 40px;
      }
      @include responsive(desktop) {
        margin-bottom: 100px;
      }
      @include responsive(wide) {
        margin-bottom: 0;
      }
    }
    .about-section__content {
      padding: 0 0 0 (70px/3);
      @include responsive(tablet) {
        padding: 0;
        width: 100%;
      }
      @include responsive(wide) {
        padding-left: 88px;
        max-width: 50%;
      }
    }
    &__container {
      overflow: hidden;
      position: relative;
    }
    &__list {
      white-space: nowrap;
      font-size: 0;
      position: relative;
      pointer-events: none;
    }
    &__item {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      &-image {
        display: block;
        width: 100%;
      }
    }
    &-nav {
      margin-top: (115px/3);
      position: relative;
      font-size: 0;
      @include responsive(tablet) {
        margin-top: 90px;
      }
      &__button {
        display: inline-block;
        border-radius: 50%;
        width: (110px/3);
        height: (110px/3);
        border: (3px/3) solid #ccc;
        position: relative;
        @include transition(300ms);
        @include responsive(tablet) {
          border-width: 1px;
          width: 65px;
          height: 65px;
          &:hover {
            transform:  scale(1.2,1.2);
          }
          &:active {
            transform:  scale(1,1);
          }
        }

        &:after {
          display: block;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: (-11px/3);
          margin-left: (-7px/3);
          @include responsive(tablet) {
            margin-top: -5px;
            margin-left: -3px;
          }
        }
        &--prev:after {
          border-width: (11px/3) (14px/3) (11px/3) 0;
          border-color: transparent #333333 transparent transparent;
          @include responsive(tablet) {
            border-width: 5px 7px 5px 0;
          }
        }
        &--next:after {
          border-width: (11px/3) 0 (11px/3) (14px/3);
          border-color: transparent transparent transparent #333333;
          @include responsive(tablet) {
            border-width: 5px 0 5px 7px;
          }
        }
        & ~ & {
          margin-left: (60px/3);
          @include responsive(tablet) {
            margin-left: 30px;
          }
        }
      }
    }
  }
}