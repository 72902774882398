.client {
  background-color: #f4f4f4;
  @include responsive(wide) {
    .project-header {
      position: absolute;
      left: 165px;
      top: 140px;
      padding-left: 0;
      width: 40%;
    }
  }
  &-group {
    & ~ & {
      margin-top: (190px/3);
      @include responsive(tablet) {
        margin-top: 120px;
      }
      @include responsive(wide) {
        margin-top: 90px;
      }
    }
    &__title {
      margin-bottom: 10px;
      font-family: $opensans-font;
      font-size: 15px;
      @include responsive(tablet) {
        font-size: 20px;
        margin-bottom: 13px;
      }
      @include responsive(wide) {
        font-size: 18px;
      }
      color: #525258;
      font-weight: bold;
    }
  }
  &-container {
    padding: 0 (70px/3) (190px/3);
    margin-top: (100px/3);
    @include responsive(tablet) {
      padding: 0 13px 100px 13px;
      margin-top: 120px;
    }
    @include responsive(desktop) {
      padding: 0 60px 100px;
    }
    @include responsive(wide) {
      width: percentage(845px/1750px);
      float: right;
      padding: 0 170px 100px 0;
      margin-top: 0;
    }
  }
  &-list {
    font-size: 0;
    margin: 0 -10px;
  }
  &-item {
    display: inline-block;
    width: 33.33%;
    padding: 0 10px;
    &__wrapper {
      position: relative;
      padding-top: 85%;
      font-size: 28.5px;
      @include responsive(tablet) {
        font-size: 50px;
      }
    }
    @include responsive(desktop) {
      width: 25%;
    }
    @media all and (min-width:1280px) and (max-width:1439px) {
      width: 20%;
    }
    @media all and (min-width:1440px) and (max-width:1599px) {
      width: 16.666%;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
}