.visual-footer {
  display: none;
  position: absolute;
  bottom: 40px;
  z-index: 10;
  width: 100%;
  text-align: center;
  @include responsive(wide) {
    display: block;
  }
  &__button {
    position: absolute;
    right: 40px;
    bottom: 0px;
  }
}