.project {

  // Layout
  & {
    position: relative;
    padding-top: (210px/3);
    @include responsive(tablet) {
      padding-top: 130px;
      padding-left: 105px;
      /*background-color: #f4f4f4;*/
      background-color: #fff;
    }
    @include responsive(desktop) {
      padding-top: 140px;
    }
    @include responsive(wide) {
      padding-left: 105px;
    }
  }

  &-wrapper {
    position: relative;
  }

  // Navigation
  &-nav {
    margin-bottom: (135px/3);
    padding: 0 (70px/3);
    position: relative;
    z-index: 10;
    @include responsive(tablet) {
      padding: 0 13px;
      margin-bottom: 120px;
    }
    @include responsive(wide) {
      padding: 0 60px;
      margin-bottom: 150px;
    }
    &__list {
      font-size: 0;
    }
    &__item {
      display: inline-block;
      & ~ & {
        margin-left: (150px/3);
        @include responsive(tablet) {
          margin-left: 45px;
        }
        @include responsive(wide) {
          margin-left: 60px;
        }
      }
      &-anchor {
        font-family: $maven-font;
        font-size: (45px/3);
        letter-spacing: .1em;
        color: #959595;
        @include transition(300ms);
        font-weight: bold;
        position: relative;
        @include responsive(tablet) {
          font-size: 15px;
        }
        @include responsive(desktop) {
          font-size: 16px;
        }
        &:after {
          @include transition(300ms);
          display: block;
          content: '';
          background-color: currentColor;
          position: absolute;
          top: 50%;
          left: 0;
          width: 0;
          height: (3px/3);
          transform: translateY(-50%);
          @include responsive(tablet) {
            height: 1px;
          }
        }
        &:hover,
        &--active {
          color: #34343a;
          padding-left: (60px/3);
          @include responsive(tablet) {
            padding-left: 25px;
          }
          &:after {
            width: (30px/3);
            @include responsive(tablet) {
              width: 15px;
            }
            @include responsive(desktop) {
              width: 15px;
            }
          }
        }
      }
    }
  }

  // Header
  &-header {
    padding: 0 (70px/3) (70px/3);
    @include responsive(tablet) {
      padding: 0 13px 30px;
    }
    @include responsive(desktop) {
      padding: 0 60px 60px;
    }
    &__wrapper {
      @include responsive(wide) {
        &--fixed {
          position: fixed;
          top: 50px;
          .client & {
            top: 140px;
          }
        }
      }
    }
  }

  // Titlte
  &-title {
    font-size: (110px/3);
    font-family: $maven-font;
    font-weight: normal;
    letter-spacing: 0.01em;
    @include responsive(tablet) {
      font-size: 85px;
      letter-spacing: -0.025em;
    }
    @include responsive(desktop) {
      font-size: 100px;
      font-weight: normal;
    }
  }

  // Description
  &-description {
    font-size: (36px/3);
    color: #525258;
    line-height: (62 / 36);
    margin-top: (50px/3);
    letter-spacing: -0.025em;
    @include responsive(tablet) {
      font-size: 18px;
      line-height: (32/18);
      margin-top: 25px;
    }
    @include responsive(desktop) {
      font-size: 18px;
      margin-top: 65px;
    }
  }

  &-container {
    @include clearfix;
  }

}