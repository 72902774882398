.agreement-content {

  p, ol, ul, dl {
    margin-top: 1em;
  }

  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }

  section {
    margin-top: 1.3em;
  }

  section section {
    margin-top: .75em;
  }

}