.portfolio {

  &-header {
    display: none;
    position: fixed;
    width: 105px;
    left: 10px;
    top: 0;
    height: 100%;
    background-color: #ffffff;
    white-space: nowrap;
    z-index: 4;
    &__wrapper {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform-origin: 0% 50%;
      transform: rotate(-90deg);
    }
    &__title {
      font-family: $opensans-font;
      font-size: 20px;
      letter-spacing: 0.025em;
      display: inline-block;
      font-weight: 600;
      transform: translate3d(0,0,0);
      @include responsive(desktop) {
        font-size: 24px;
      }
      &:before {
        width: 90px;
        height: 1px;
        background-color: #525258;
        display: inline-block;
        vertical-align: middle;
        content: '';
        margin-right: 20px;
        @include responsive(desktop) {
          margin-right: 20px;
        }
      }
    }
    @include responsive(tablet) {
      display: block;
    }
    @include responsive(desktop) {
      left: 10px;
    }
    .main-page & {
      left: 0;
      position: absolute;
    }
  }

  @include responsive(tablet) {
    & .project-nav {
      display: block;
      position: absolute;
      top: 130px;
      padding-left: 40px;
    }
    &.portfolio2 {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    &.portfolio2 .project-container {
      height: 100%;
    }
  }
  @include responsive(desktop) {
    & .project-header {
      width: 105px;
    }
    .project-nav {
      top: 140px;
    }
  }
  @include responsive(wide) {
    .project-nav {
      width: 32.39053%;
    }
  }
  // Change View Button
  &-change {
    display: none;
    @include responsive(tablet) {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: 60px;
      cursor: pointer;
    }
    @include responsive(wide) {
      position: absolute;
      right: 0;
      top: 0;
    }
    position: absolute;

    &__input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    &__input:checked ~ &__icon {
      & .portfolio-change__icon-group {
        &-item {
          border-radius: 0;
          transform: scale(4, 0.6);
        }
      }
    }
    &__icon {
      font-size: 0;
      display: inline-block;
      overflow: hidden;
      &-group {
        display: block;
        & ~ & {
          margin-top: 3px;
        }
        &-item {
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #343132;
          & ~ & {
            margin-left: 3px;
          }
        }
      }
    }
  }
  &-nav {
    display: none;
    @include responsive(tablet) {
      .portfolio2 & {
        display: block;
      }
      padding-top: 290px;
      width: percentage(269px / 643px);
      float: left;
      height: 100%;
    }
    @include responsive(desktop) {
      padding-top: 310px;
      width: 42%;
    }
    @include responsive(wide) {
      width: 32.39053%;
    }
    &__wrapper {
      @include responsive(tablet) {
        height: 100%;
        overflow-y: auto;
      }
    }
    &__list {
      /*position: relative;*/
    }
    &__item {
      &-clients {
        position: absolute;
        left: 0;
        top: 24px;
        font-family: $opensans-font;
        font-size: 14px;
        letter-spacing: -.01em;
        color: $font-color;
        transition: all 500ms ease-in-out;
        @include opacity(1);
        @include responsive(tablet) {
          padding-left: 34px;
        }
        @include responsive(wide) {
          top: 30px;
          padding-left: 93px;
        }
        .portfolio-nav__item-anchor--active & {
          @include opacity(0);
        }
      }
      &-value {
        position: relative;
        color: #343132;
        font-family: $opensans-font;
        font-size: 22px;
        display: inline-block;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal;
        @include transition(300ms);
        @include responsive(tablet) {
          font-size: 22px;
        }
        @include responsive(wide) {
          font-size: 22px;
          position: relative;
          padding-left: 33px;
          &:hover {
            &:before,
            &:after {
              will-change: transform, margin;
            }
          }
          &:before,
          &:after {
            transition: all 300ms;
            content: '';
          }
          &:before {
            display: inline-block;
            width: 0;
            height: 1px;
            background-color: #ffffff;
            vertical-align: middle;
            position: relative;
            top: -2px;
          }
          &:after {
            display: block;
            width: 10px;
            height: 10px;
            border-right: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            position: absolute;
            top: 50%;
            left: 63px;
            transform: translate3d(0, -50%, 0) rotate(-45deg) scale(0, 0);
            margin-top: 0.05em;
            opacity: 0;
          }
        }
        @include responsive(wide) {
          font-size: 30px;
        }
      }
      &-anchor--active &-value {
        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        @include responsive(tablet) {
          font-size: 24px;
        }
        @include responsive(wide) {
          font-size: 24px;
          padding-left: 102px;
          &:before {
            position: absolute;
            width: 72px;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            margin-right: 30px;
          }
          &:after {
			  top: 0;
			  bottom: 0;
			  margin: auto;
			  opacity: 1;
			  transform-origin: 100% 100%;
			  transform: translate3d(0, -50%, 0) rotate(-45deg) scale(1, 1);
          }
        }
        @include responsive(wide) {
          font-size: 42px;
        }
      }
      &-anchor {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 500ms;
        position: relative;
        &:after {
          content: attr(data-index);
          font-family: $roboto-font;
          font-weight: 600;
          font-size: 16px;
          position: absolute;
          left: 13px;
          top: 24px;
          transform: rotate(-90deg);
          color: #343132;
          z-index: 10;
          @include transition(300ms);
          @include responsive(tablet) {
            font-size: 16px;
          }
          @include responsive(wide) {
            font-size: 16px;
            left: 56px;
            top: 30px;
          }
        }
        &--active {
          background-color: currentColor;
          &:after {
            transform: rotate(0);
            opacity: 0;
            color: #ffffff;
          }
          @include responsive(wide) {
            padding-top: 33px;
            padding-bottom: 33px;
          }
        }
        @include responsive(tablet) {
          padding: 40px 0 40px 34px;
        }
        @include responsive(wide) {
          padding: 50px 0 50px 60px;
        }
      }
    }
  }
  &-container {
    position: relative;
    @include responsive(tablet) {
      padding: 0px 30px 70px 13px;
      .portfolio2 & {
        height: 100%;
        padding: 0;
        width: percentage(374px / 643px);
        float: right;
      }
    }
    @include responsive(desktop) {
      padding: 0px 18% 70px 13px;
      .portfolio2 & {
        width: percentage(1040px / 1800px);
      }
    }
    @include responsive(wide) {
      padding: 0px 165px 60px 60px;
    }
  }
  &-list {
    margin: (-20px) 0;
    font-size: 0;
    overflow: hidden;
    position: relative;
    @include responsive(tablet) {
      margin: -40px -10px;
      .portfolio2 & {
        margin: 0;
        height: 100%;
      }
    }
    @include responsive(desktop) {
      margin: -40px -18px;
    }
  }
  &-item {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 20px 0;
    vertical-align: top;
    @include responsive(tablet) {
      margin: 40px 0;
      width: 50%;
      padding: 0 10px;
      .portfolio2 & {
        margin: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        opacity: 0;
        transition: opacity 500ms, transform 500ms;
        transform: scale(1.3,1.3);
      }
      .portfolio2 &--active {
        position: relative;
        //will-change: transform;
        opacity: 1;
        z-index: 2;
        transform: scale(1,1);
      }
    }
    @include responsive(desktop) {
      margin: 40px 0;
      padding: 0 18px;
    }
    @include responsive(wide) {
      width: 33%;
    }

    &__background {
      /*background-size: cover;*/
      width: 100%;
      height: 100%;
      position: absolute;
      /*background-position: 50% 50%;*/
      z-index: 1;
      left: 0;
      top: 0;
      /*display: none;*/
      @include responsive(only-mobile) {
        &--mobile {
          display: block;
        }
      }
      @include responsive(tablet) {
        &--tablet {
          display: block;
        }
      }
      @include responsive(wide) {
        &--tablet {
          display: none;
        }
        &--pc {
          display: block;
        }
      }

      @include responsive(tablet) {
        .portfolio1 & {
          display: none;
        }
      }
      &:after {
        display: block;
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include responsive(desktop) {
          display: none;
        }
      }
      img {
        position: absolute;
        max-width: none;
        height: 100.2%;
        width: auto;
        min-width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }
    &__image {
       display: block;
       .portfolio2 & {
         display: none;
       }
    }
    &__anchor {
      .portfolio2 & {
        //position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        @include responsive(desktop) {
          display: table;
          table-layout: fixed;
        }
      }

    }

    // award
    &__award {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 25px;
      z-index: 2;
      @include responsive(tablet) {
        font-size: 33.3px;
      }
      @include responsive(desktop) {
        font-size: 50px;
        .portfolio2 & {
          top: 40px;
          right: 40px;
        }
      }
      &-item {
        float: left;
        & ~ & {
          margin-left: 20px;
        }
      }
    }

    &__wrapper {
      @include responsive(desktop) {
        .portfolio2 & {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
    &__content {
      width: 100%;
      left: 0;
      text-align: left;
      white-space: normal;
      word-break: break-all;
      top: 0;
      color: #343132;
      margin-top: 14px;
      padding: 0 23.3px;
      .portfolio2 & {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 60px;
        color: #ffffff;
        margin-top: 0;
      }
      @include responsive(tablet) {
        margin-top: 30px;
        padding: 0 10px;
      }
      @include responsive(desktop) {
        padding: 0 13px;
        margin-top: 28px;
        .portfolio2 & {
          position: relative;
          transform: none;
          top: 0;
          background-color: rgba(0, 0, 0, 0.3);
          padding: 130px 60px;
          display: inline-block;
          width: 610px;
          max-width: 100%;
        }
      }
    }
    &__client {
      font-family: $maven-font;
      font-size: (33px/3);
      font-weight: normal;
      @include responsive(tablet) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }


    &__period {
      font-family: $maven-font;
      font-size: (33px/3);
      font-weight: normal;
      @include responsive(tablet) {
        .portfolio1 & {
          font-size: 14px;
        }
        .portfolio2 & {
          display: block;
        }
      }
      @include responsive(tablet) {
        font-size: 18px;
      }
      @include responsive(wide) {
        .portfolio1 & {
          font-size: 16px;
        }
      }
    }
    .portfolio1 &__wrapper {
      position: relative;
    }
    .portfolio1 &__anchor:focus &__subject,
    .portfolio1 &:hover &__subject {
      opacity: 1;
    }
    &__subject {
      .portfolio1 & {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 0;
        padding-top: 66%;
        top: 0;
        left: 0;
        transition: all 300ms;
        opacity: 0;
        overflow: hidden;
        &-wrapper {
          position: absolute;
          text-align: center;
          top: 50%;
          width: 100%;
          padding: 0 15px;
          transform: translateY(-50%);
          color: #ffffff;
          transition: all 300ms;
        }
      }
    }
    &__year {
      &:before {
        width: (70px/3);
        height: (3px/3);
        vertical-align: middle;
        display: inline-block;
        margin: 0 (15px/3);
        content: '';
        background-color: #ffffff;
        @include responsive(tablet) {
          width: 40px;
          height: 1px;
        }
      }
    }
    &__title {
      font-family: $maven-font;
      font-size: (100px/3);
      margin-top: (50px/3);
      font-weight: normal;
      line-height: 1.3;
      word-break: break-all;
      @include responsive(tablet) {
        .portfolio1 & {
          font-size: 30px;
          margin-top: 7px;
        }
      }
      @include responsive(wide) {
        .portfolio1 & {
          font-size: 30px;
        }
      }
    }
    &__infomation {
      &-wrapper {
        display: table;
        width: 100%;
        table-layout: fixed;
        @include responsive(tablet) {
          margin: 0;
          .portfolio2 & {
            margin-top: 75px;
          }
        }
        @include responsive(desktop) {
          .portfolio2 & {
            white-space: nowrap;
            margin-top: 90px;
          }
        }
      }
      display: table-cell;
      vertical-align: top;
      padding-right: 15px;
      width: 43%;
      & ~ & {
        width: auto;
      }
      @include responsive(tablet) {
        .portfolio2 & {
          display: block;
        }
      }
      @include responsive(desktop) {
        .portfolio2 & {
          display: inline-block;
          width: percentage(270px / 490px);
        }
      }
      @include responsive(wide) {
        .portfolio1 & {
          width: 50%;
        }
      }
      & ~ & {
        //width: 55%;
        padding-left: 15px;
        padding-right: 0;
        @include responsive(tablet) {
          padding-left: 0;
          .portfolio1 & {
            width: auto;
          }
          .portfolio2 & {
            margin-left: 0;
            margin-top: 35px;
          }
        }
        @include responsive(desktop) {
          margin-left: 0;
          .portfolio2 & {
            margin-top: 0;
          }
        }
      }
      &-title {
        font-family: $maven-font;
        font-size: (33px/3);
        font-weight: bold;
        margin-bottom: (20px/3);
        letter-spacing: 0.04em;
        @include responsive(tablet) {
          font-size: 15px;
        }
        @include responsive(desktop) {
          font-size: 15px;
        }
      }
      &-value {
        font-size: (36px/3);
        line-height: (50/36);
        font-family: $opensans-font;
        color: #525258;
        //white-space: nowrap;
        @include responsive(tablet) {
          font-size: 14px;
          line-height: (26/16);
        }
      }
      &-logo {
        font-size: 33.3px;
        display: block;
        margin-top: 7px;
        @include responsive(tablet) {
          font-size: 50px;
          margin-top: 15px;
        }
      }
    }
    &__footer {
      margin-top: 30px;
      @include responsive(tablet) {
        margin-top: 55px;
        .portfolio2.portfolio & {
          display: block;
        }
        .portfolio & {
          display: none;
        }
      }
    }
    &__button {
      width: (440px/3);
      height: 40px;
      border: 1px solid #ffffff;
      line-height: 38px;
      font-size: 12px;
      font-family: $maven-font;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      display: block;
      position: relative;
      &-value {
        position: relative;
        z-index: 2;
      }
      @include responsive(tablet) {
        width: 175px;
        height: 48px;
        line-height: 46px;
        font-size: 16px;
        transition: all 300ms;
        &:after {
          position: absolute;
          width: 0;
          height: 100%;
          transition: all 300ms;
          background-color: #ffffff;
          display: block;
          content: '';
          top: 0;
          left: 0;
        }
        &:hover {
          color: #343132;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}