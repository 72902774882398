.visual {
  position: relative;
  height: 100%;
  overflow: hidden;
  .lt-ie11 & {
    border: 10px solid $red-color;
  }
  &-information {
    display: none;
  }
  &-wrapper,
  &-item {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-item {
    opacity: 0;
    transition: all .5s;
    z-index: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    .lt-ie9 & {
      display: none;
    }
    &--active {
      position: relative;
      z-index: 2;
      opacity: 1;
      .lt-ie9 & {
        display: block;
      }
    }
    counter-increment: visual-item;
    &[data-letter="D"] {
      color: #ffffff;
    }
    &:before {
      content: '0'counter(visual-item);
      font-family: $roboto-font;
      font-size: rem(40);
      display: inline-block;
      transform: rotate(-90deg);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: (-400px/3);
      margin-top: (-260px/3);
      font-weight: bold;
      z-index: 10;
      @include responsive(tablet) {
        margin-left: -256px;
        margin-top: -167px;
        font-size: 22px;
      }
      @include responsive(wide) {
        font-size: 24px;
        margin-left: -437px;
        margin-top: -135px;
      }
    }
  }

  &-title,
  &-subject,
    //&-content,
  &-item:before {
    transition: all 500ms;
    transform: translate(-100px, 0);
    opacity: 0;
  }
  &-item:before {
    transform:  translate(-100px, 0) rotate(-90deg);
  }

  &-item--active &-title,
  &-item--active &-subject,
  &-item--active:before {
    transform: translate(0, 0);
    will-change: transform;
    opacity: 1;
  }
  &-item--active:before {
    transform: rotate(-90deg) translate(0, 0);
  }
  &-item--active &-subject {
    opacity: .2;
  }


  &-title,
  &-subject,
  &-content {
    font-family: $maven-font;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 40;
    [data-letter="D"] & {
      color: #ffffff;
    }
  }
  &-title {
    transition-delay: .4s;
    letter-spacing: -0.025em;
    font-weight: bold;

    [data-letter="D"] & {
      font-size: 40px;
      @include responsive(tablet) {
        font-size: 70px;
      }
      @include responsive(wide) {
        font-size: 80px;
      }
    }
    [data-letter="C"] &,
    [data-letter="V"] & {
      font-size: 33px;
      @include responsive(tablet) {
        font-size: 70px;
      }
      @include responsive(wide) {
        font-size: 80px;
      }
    }

    [data-letter="D"] & {
      margin-left: (-140px/3);
      margin-top: (155px/3);
      @include responsive(tablet) {
        margin-left: -60px;
        margin-top: 100px;
      }
      @include responsive(wide) {
        margin-left: 88px;
        margin-top: 60px;
      }
    }
    [data-letter="C"] & {
      margin-left: (-175px/3);
      margin-top: (155px/3);
      @include responsive(tablet) {
        margin-left: -140px;
        margin-top: 100px;
      }
      @include responsive(wide) {
        margin-left: 90px;
        margin-top: 63px;
      }
    }
    [data-letter="V"] & {
      margin-left: (-72px/3);
      margin-top: 50px;
      @include responsive(tablet) {
        margin-left: -65px;
        margin-top: 100px;
      }
      @include responsive(wide) {
        margin-left: 85px;
        margin-top: 60px;
      }
    }
    [data-letter="S"] & {
      margin-left: (-221px/3);
      margin-top: 50px;
      font-size: 33px;
      @include responsive(tablet) {
        margin-left: -182px;
        margin-top: 100px;
        font-size: 70px;
      }
      @include responsive(wide) {
        margin-left: 87px;
        margin-top: 60px;
        font-size: 80px;
      }
    }

  }
  &-subject {
    transition-delay: .2s;
    opacity: .2;
    font-size: 20px;
    font-weight: bold;
    @include responsive(tablet) {
      font-size: 40px;
    }
    @include responsive(wide) {
      font-size: 50px;
    }
    [data-letter="D"] & {
      margin-left: (-140px/3);
      margin-top: (130px/3);
      @include responsive(tablet) {
        margin-left: -60px;
        margin-top: 85px;
      }
      @include responsive(wide) {
        margin-left: 88px;
        margin-top: 40px;
      }
    }
    [data-letter="C"] & {
      margin-left: (-170px/3);
      margin-top: (130px/3);
      @include responsive(tablet) {
        margin-left: -140px;
        margin-top: 85px;
      }
      @include responsive(wide) {
        margin-left: 88px;
        margin-top: 40px;
      }
    }
    [data-letter="V"] & {
      margin-left: (-80px/3);
      margin-top: (130px/3);
      @include responsive(tablet) {
        margin-left: -67px;
        margin-top: 85px;
      }
      @include responsive(wide) {
        margin-left: 86px;
        margin-top: 40px;
      }
    }
    [data-letter="S"] & {
      margin-left: (-220px/3);
      margin-top: 42px;
      @include responsive(tablet) {
        margin-left: -180px;
        margin-top: 85px;
      }
      @include responsive(wide) {
        margin-left: 88px;
        margin-top: 40px;
      }
    }
  }
  &-content {
    font-size: 20px;
    line-height: (66 / 60);
    letter-spacing: -0.01em;
    @include responsive(tablet) {
      font-size: 40px;
    }
    @include responsive(wide) {
      font-size: 50px;
    }
    &-letter {
      display: none;
      position: relative;
      @keyframes visual-letter-typing {
        0%, 50% {
          visibility: visible;
        }
        51%, 100% {
          visibility: hidden;
        }
      }
      &:after {
        content: '_';
        position: absolute;
        left: 100%;
        display: none;
        top: 0;
      }
      &--active {
        display: inline;
      }
      &--current:after {
        display: block;
        animation: visual-letter-typing 500ms linear infinite alternate;
      }
    }
    & {
      margin-left: (-400px/3);
      margin-top: (-200px/3);
      @include responsive(tablet) {
        margin-left: -255px;
        margin-top: -120px;
      }
      @include responsive(wide) {
        margin-left: -436px;
        margin-top: -83px;
      }
    }

  }
  &-letter {
    display: block;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5,1.5);
    opacity: 0;
    background-size: 100% 100%;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        transition-delay: #{($i - 1) * 400}ms;
      }
    }
    transition: all 500ms;
    #visual .visual-item--active & {
      transform: translate(-50%, -50%) scale(1,1);
      will-change: transform;
      opacity: 1;
    }
    [data-letter="D"] & {
      width: (522px/3);
      height: (574px/3);
      @include responsive(tablet) {
        width: 311px;
        height: 341px;
      }
      @include responsive(wide) {
        width: 397px;
        height: 437px;
      }
    }
    [data-letter="C"] & {
      width: (577px/3);
      height: (605px/3);
      @include responsive(tablet) {
        width: 347px;
        height: 366px;
      }
      @include responsive(wide) {
        width: 452px;
        height: 475px;
      }
    }
    [data-letter="V"] & {
      width: 220px;
      height: (585px/3);
      @include responsive(tablet) {
        width: 397px;
        height: 353px;
      }
      @include responsive(wide) {
        width: 516px;
        height: 458px;
      }
    }
    [data-letter="S"] & {
      width: (555px/3);
      height: (603px/3);
      @include responsive(tablet) {
        width: 334px;
        height: 364px;
      }
      @include responsive(wide) {
        width: 435px;
        height: 471px;
      }
    }
    &__svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    &--background {
      z-index: 10;
      [data-letter="D"] & {
        background-image: url(../images/letter-d.png);
        background-image: url(../images/letter-d.svg), none;
      }
      [data-letter="C"] & {
        background-image: url(../images/letter-c-fill.png);
        background-image: url(../images/letter-c-fill.svg), none;
      }
      [data-letter="V"] & {
        background-image: url(../images/letter-v.png);
        background-image: url(../images/letter-v.svg), none;
      }
      [data-letter="S"] & {
        background-image: url(../images/letter-s.png);
        background-image: url(../images/letter-s.svg), none;
      }
    }
    &--overlay {
      z-index: 20;
      [data-letter="D"] & {
        background-image: url(../images/letter-d-overlap.png);
        background-image: url(../images/letter-d-overlap.svg), none;
        margin-left: (-48px/3);
        margin-top: (36px/3);
        @include responsive(wide) {
          margin-top: 27px;
          margin-left: -30px;
        }
      }
      [data-letter="C"] & {
        background-image: url(../images/letter-c-dot.png);
        background-image: url(../images/letter-c-dot.svg), none;
        margin-left: (-30px/3);
        margin-top: (-25px/3);
        @include responsive(wide) {
          margin-top: -21px;
          margin-left: -21px;
        }
      }
      [data-letter="V"] & {
        background-image: url(../images/letter-v-sprite.png);
        background-image: url(../images/letter-v-sprite.svg), none;
        margin-left: (-25px/3);
        margin-top: (-30px/3);
        @include responsive(wide) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
      [data-letter="S"] & {
        background-image: url(../images/letter-s-sprite.png);
        background-image: url(../images/letter-s-sprite.svg), none;
        margin-left: (-30px/3);
        margin-top: (-30px/3);
        @include responsive(wide) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
    &--fill {
      z-index: 30;
      [data-letter="D"] &,
      [data-letter="V"] & {
        transform: translate(-50%, -50%) scale(1.5,1.5) rotate(180deg);
      }
      [data-letter="C"] &,
      [data-letter="S"] & {
        transform: translate(-50%, -50%) scale(1.5,1.5) rotate(90deg);
      }
      [data-letter="D"] & {
        margin-left: (27px/3);
        margin-top: (-27px/3);
        @include responsive(wide) {
          margin-top: -20px;
          margin-left: 20px;
        }
      }
      [data-letter="C"] & {
        background-image: url(../images/letter-c-half.png);
        background-image: url(../images/letter-c-half.svg), none;
        margin-left: (-30px/3);
        margin-top: (-30px/3);
        @include responsive(wide) {
          margin-top: -21px;
          margin-left: -21px;
        }
      }
      [data-letter="V"] & {
        background-image: url(../images/letter-v-half.png);
        background-image: url(../images/letter-v-half.svg), none;
        margin-left: (-30px/3);
        margin-top: (-30px/3);
        @include responsive(wide) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
      [data-letter="S"] & {
        background-image: url(../images/letter-s-half.png);
        background-image: url(../images/letter-s-half.svg), none;
        margin-left: (-30px/3);
        margin-top: (-30px/3);
        @include responsive(wide) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

}


/* holiday */
.holiday-wrapper {
  display: table;
  clear: both;
  content: '';
}
.holiday-visual {
  width: (960px/3);
  height: (673px/3);
  background-image: url('../../images/flag_of_south_korea.svg');
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  animation-name: flag;
  animation-duration: 3s;
  transform: translate(-50%,-50%);
  @keyframes flag {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @include responsive(tablet) {
    width: 930px;
    height: 728px;
  }
  @include responsive(wide) {
    width: 1205px;
    height: 728px;
    top: 43%;
  }
  &__desc {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: (-120px/3);
    letter-spacing: -0.3px;
    font-size: (33px/3);
    color: #525258;
    line-height: 1.5;
    font-family: $opensans-font;
    animation-name: flag_desc;
    animation-duration: 8s;
    opacity: 1;
    @include responsive(tablet) {
      text-align: center;
      font-size: 28px;
      letter-spacing: -0.3px;
      line-height: 1.5;
      bottom: 0;
    }
    @include responsive(wide) {
      bottom: -160px;
      line-height: 1.8;
      letter-spacing: -0.2px;
      font-size: 20px;
    }
    @keyframes flag_desc {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    text-align: center;
  }
}