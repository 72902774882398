@include responsive(desktop) {
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 9px;
    height: 9px;
    &-track {
      width: 9px;
      background-color: rgba(0, 0, 0, 0.1);
      @include border-radius(9px);
    }
    &-thumb {
      background-color: $point-color;
      width: 5px;
      @include border-radius(5px);
    }
    &-button {
      &:start:decrement,
      &:end:increment {
        background-color: transparent;
        height: 10px;
      }
    }
  }
}


.scroll-hide {
  overflow: hidden;
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-3dLight-Color: #efefef;
  scrollbar-arrow-color: #dfdfdf;
  scrollbar-base-color: #efefef;
  scrollbar-Face-Color: #dfdfdf;
  scrollbar-Track-Color: #efefef;
  scrollbar-DarkShadow-Color: #efefef;
  scrollbar-Highlight-Color: #efefef;
  scrollbar-Shadow-Color: #efefef
}