@import "_setting";
/*
 * Reset All Elements Box Sizing
 */
*,
*:before,
*:after {
	box-sizing: inherit;
}
/*
 * Inherit HTML Element Box Sizing
 */
html {
	box-sizing: border-box;
	overflow-y: scroll;
}
/*
 * Remove Margin & Paddings
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, main,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html, body {
	width: 100%;
	height: 100%;
}
body {
	line-height: 1;
	font-size: $body-font-size;
	color: $body-font-color;
	font-family: $body-font-family;
}
#body {
	color: $body-font-color;
}
a {
	text-decoration: none;
	color:inherit;
}
button,
[type=reset],
[type=button], 
[type=submit] {
	overflow:visible;
	cursor:pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	padding: 0;
}
[type=text],
[type=password],
[type=number],
[type=email],
[type=search],
[type=tel],
textarea {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0;
}
input, button, textarea, select, button {
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	border: 0;
	background-color: transparent;
	-webkit-text-size-adjust: inherit;
}
img {
	max-width: 100%;
	height: auto;
}
figure img {
	display: block;
	width: 100%;
}
::selection {
	background-color: $red-color;
	color: #ffffff;
}