.infomation {
  background-color: #fafafa;
  &__container {
    padding: (90px/3) (70px/3);
    max-width: 1220px;
    @include responsive(tablet) {
      width: 68%;
      margin: 0 auto;
      padding: (100px) 0;
    }
    @include responsive(wide) {
      padding: 60px 0;
      width: 90%;
    }
  }
  &__list {
    @include clearfix;
    //margin: (-45px/3) (-15px/3);
    margin: -15px -4px;
    font-size: 0;
    @include responsive(tablet) {
      margin: -40px -60px;
    }
    @include responsive(wide) {
      white-space: nowrap;
      margin: 0;
    }
  }
  &__item {
    width: 50%;
    vertical-align: top;

    display: inline-block;
    //margin: (45px/3) (15px/3);
    padding: 15px 4px;
    font-family: $opensans-font;
    letter-spacing: .025em;
    color: #525258;

    @include responsive(tablet) {
      padding: 40px 60px;
    }
    @include responsive(wide) {
      padding: 0;
      padding-right: 60px;
      width: 25%;
      white-space: normal;
      margin: 0;
    }

    &-title {
      font-weight: bold;
      font-size: (33px/3);
      margin-bottom: (24px/3);
      padding-left: (39px/3);
      position: relative;
      @include responsive(tablet) {
        font-size: 18px;
        padding-left: 21px;
        margin-bottom: 15px;
      }
      &:before {
        width: (20px/3);
        height: 1px;
        background-color: #525258;
        display: block;
        position: absolute;
        left: 0;
        top: (15px/3);
        content: '';
        @include responsive(tablet) {
          width: 10px;
          top: 50%;
          margin-top: -2px;
        }
      }
    }
    &-content {
      font-weight: normal;
      font-size: (30px/3);
      line-height: (50/30);
      padding-left: (39px/3);
      @include responsive(tablet) {
        font-size: 16px;
        padding-left: 21px;
        line-height: (28/16);
      }
    }
  }
  &__footer {
    font-size: 10px;
    margin-top: 30px;
    padding-left: (39px/3);
    @include responsive(tablet) {
      font-size: 16px;
      margin-top: 50px;
      padding-left: 21px;
    }
    line-height: 1.5;
    address {
      margin-top: 10px;
    }
  }
}