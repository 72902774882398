$grid-column: 12;
.row {
  display: table;
  width: 100%;
  @for $i from 1 through $grid-column {
    .col-#{$i} {
      float: left;
      width: ((100% / $grid-column * $i));
    }
  }
}