// Agreement Confirm
.agreement-confirm {
  &__button {
    color: #ffffff;
    font-family: $maven-font;
    letter-spacing: 0.01em;
    height: 40px;
    line-height: 40px;
    display: block;
    width: 100%;
    transition: all 300ms;
    background-color: #777777;
    &.mw-layer-close-area {
      background-color: $red-color;
    }
    @include responsive(tablet) {
      font-size: 15px;
      height: 48px;
      line-height: 48px;
    }
  }
}

.contact-form-layer {
  &::after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 99;
    content: '';
    top: 0;
    left: 0;
    @include transition(750ms);
    opacity: 0;
    display: block;
    pointer-events: none;
    .lt-ie11 & {
      display: none;
    }
  }
  &--active::after {
    opacity: 1;
    pointer-events: auto;
    .lt-ie11 & {
      display: block;
    }
  }
}

.contact {
  &-form {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: $red-color;
    left: 0;
    top: 0;
    color: #ffffff;
    @include transition(750ms);
    transform: translate(0, -100%);
    pointer-events: none;
    @include responsive(wide) {
      right: 0;
      left: auto;
      max-width: 700px;
      transform: translate(100%, 0%);
    }
    &--active {
      transform: translate(0, 0%);
      pointer-events: auto;
    }
    &__close {
      width: (110px/3);
      height: (110px/3);
      position: absolute;
      right: (45px/3);
      top: (35px/3);
      z-index: 10;
      @include responsive(tablet) {
        width: 45px;
        height: 45px;
        right: 40px;
        top: 30px;
      }
      @include responsive(wide) {
        right: 40px;
        top: 40px;
        width: 45px;
        height: 45px;
      }
      &:after,
      &:before {
        position: absolute;
        display: block;
        content: '';
        background-color: #ffffff;
        width: (75px/3);
        height: (6px/3);
        top: 50%;
        left: 50%;
        margin-top: (-3px/2/3);
        margin-left: (-75px/2/3);
        @include responsive(tablet) {
          width: 33px;
          margin-left: -16.5px;
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &__scroller {
      overflow-y: scroll;
      position: relative;
      height: 100%;
    }
    &__wrapper {
      position: relative;
      padding: (250px/3) (80px/3) (150px/3);
      @include responsive(tablet) {
        padding: 135px 69px 90px;
      }
      @include responsive(desktop) {
        padding: 165px 120px 100px;
      }
      @include responsive(wide) {
        padding: 150px 90px 90px;
      }
    }
    &__title {
      font-family: $maven-font;
      font-size: (80px/3);
      line-height: (100 / 80);
      letter-spacing: -0.01em;
      @include responsive(tablet) {
        font-size: 50px;
      }
      @include responsive(wide) {
        font-size: 50px;
      }
    }
    &__container {
      margin-top: (160px/3);
      @include responsive(tablet) {
        margin-top: 80px;
      }
      @include responsive(wide) {
        margin-top: 75px;
      }
    }
    &__group {
      &-item {
        & ~ & {
          margin-top: (115px/3);
          @include responsive(tablet) {
            margin-top: 0;
          }
        }
      }
      @include responsive(tablet) {
        font-size: 0;
        margin: 0 -10px;
        &-item {
          width: 50%;
          display: inline-block;
          padding: 0 10px;
        }
      }
      @include responsive(only-desktop) {
        margin: 0 -20px;
        &-item {
          padding: 0 20px;
        }
      }
    }
    &__item ~ &__group,
    &__group ~ &__group,
    &__group ~ &__item {
      margin-top: (115px/3);
      @include responsive(tablet) {
        margin-top: 40px;
      }
    }

    // Checkbox
    &__checkbox {
      position: relative;
      cursor: pointer;

      &-input {
        position: absolute;
        opacity: 0;
      }
      &-input:checked ~ &-shape {
        opacity: 1;
        &:before {
          transform: scale(1,1);
        }
        &:after {
          transform: scale(1,1) rotate(-45deg) translate(0,0);
        }
      }
      .contact-form__item-label-value {
        opacity: .5;
      }
      &-input:checked ~ .contact-form__item-label-value {
        opacity: 1;
      }
      &-shape {
        position: relative;
        border: 1px solid #fff;
        border-radius: 3px;
        transition: all 300ms;
        opacity: .5;

        display: inline-block;
        vertical-align: middle;

        width: (40px/3);
        height: (40px/3);
        margin-right: 5px;

        @include responsive(tablet) {
          border-width: 2px;

          width: 20px;
          height: 20px;
          margin-right: 10px;

        }
        &:before,
        &:after {
          @include transition(300ms);
        }
        &:before {
          background-color: $red-color;
          width: (6/16*100%);
          height: (9/16*100%);

          position: absolute;
          display: block;
          content: '';
          right: -1px;
          top: -1px;
          @include responsive(tablet) {
            right: -2px;
            top: -2px;
          }
          transform: scale(0,0);
        }
        &:after {
          border: 1px solid #fff;
          display: block;
          width: 100%;
          height: 40%;
          content: '';
          border-top: none;
          border-right: none;
          transform-origin: 20% 50%;
          transform: scale(0,0) rotate(-45deg) translate(0,-50%);
          position: absolute;
          bottom: 3px;
          left: 2px;
          @include responsive(tablet) {
            border-width: 2px;
            bottom: 4px;
            left: 3px;
          }
        }
      }

    }

    &__item {
      position: relative;
      border-bottom: 1px solid #ffffff;
      & ~ & {
        margin-top: (115px/3);
        @include responsive(tablet) {
          margin-top: 40px;
        }
      }
      &-input {
        width: 100%;
        display: block;
        height: (90px/3);
        line-height: (90px/3);
        font-size: (36px/3);
        resize: none;
        @include responsive(tablet) {
          font-size: 18px;
          height: 45px;
          line-height: 45px;
        }
        letter-spacing: -0.01em;
        z-index: 10;
        position: relative;
        @at-root select#{&},
        &[type=file] {
          opacity: 0;
          color: #333333;
        }
      }

      .lt-ie10 &-input ~ &-label {
        transform: scale(0.83, 0.83) translateY(-45px);
        & .contact-form__item-label-value {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
      &-input:valid ~ &-label,
      &-input:focus ~ &-label {
        transform: scale(0.83, 0.83) translateY(-45px);
        & .contact-form__item-label-value {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
      &-selectvalue,
      &-filename {
        position: relative;
        display: block;
        height: (90px/3);
        width: 100%;
        padding-right: (55px/3);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include responsive(wide) {
          padding-right: 30px;
        }
      }
      &-warning {
        position: absolute;
        bottom: -20px;
      }
      &-button {
        width: (30px/3);
        height: (30px/3);
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: (-15px/3);
        @include responsive(desktop) {
          width: 15px;
          height: 15px;
          margin-top: -7px;
        }
      }

      &-selectvalue {
        &:after {
          width: (30px/3);
          height: (30px/3);
          border-left: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          display: block;
          content: '';
          position: absolute;
          right: (10px/3);
          top: 50%;
          margin-top: (-15px/3);
          transform: rotate(-45deg);
          @include responsive(desktop) {
            width: 15px;
            height: 15px;
            right: 7px;
            margin-top: -7px;
          }
        }
      }
      &-button,
      &-filename {
        &:after,
        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: (-2px/3);
          width: (40px/3);
          height: (1px);
          background-color: #ffffff;
          @include responsive(desktop) {
            width: 20px;
            height: 2px;
          }
        }
        &:after {
          transform: rotate(90deg);
        }
      }
      &-selectvalue,
      &-filename,
      &-label {
        @include transition(300ms);
        transform-origin: 0 50%;
        line-height: (90px/3);
        position: absolute;
        left: 0;
        top: 0;
        font-family: $opensans-font;
        font-size: (36px/3);
        @include responsive(tablet) {
          font-size: 18px;
          line-height: 45px;
          height: 45px;
        }
        letter-spacing: -0.01em;
      }
      &-label {
        &--agreement {
          position: relative;
          display: block;
        }
        &:after,
        &-value {
          @include transition(300ms);
          vertical-align: middle;
        }
        &:after {
          content: attr(title);
          opacity: 0;
          position: absolute;
          white-space: nowrap;
          left: 0;
          top: 0;
        }
      }
    }
    &__footer {
      margin-top: (120px/3);
      @include responsive(tablet) {
        margin-top: 40px;
      }
    }
    &__button {
      display: block;
      text-align: center;
      width: (440px/3);
      padding: (40px/3) 0;
      font-family: $maven-font;

      font-size: (36px/3);
      border: 1px solid #fff;
      margin-left: auto;
      position: relative;
      font-weight: 500;
      @include responsive(tablet) {
        font-size: 15px;
        padding: 18px 0;
        width: 175px;
        font-weight: bold;
      }
      @include transition(300ms);
      &:active,
      &:hover {
        color: $red-color;
        &:after {
          width: 100%;
        }
      }
      &:after {
        width: 0;
        z-index: -1;
        @include transition(300ms);
        height: 100%;
        background-color: #ffffff;
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  &-wrapper {
    max-width: 1670px;
    margin: 0 auto;
    font-size: 0;
    @include responsive(wide) {
      padding: 140px 50px 75px;
    }
  }
  &-title {
    font-family: $maven-font;
    font-weight: 500;
    font-size: (110px/3);
    letter-spacing: -0.025em;
    margin-bottom: (100px/3);

    /* Padding */
    padding: (250px/3) (69px/3) 0;
    @include responsive(tablet) {
      padding: 130px 110px 0;
    }
    @include responsive(wide) {
      padding: 0;
    }

    @include responsive(tablet) {
      font-size: 85px;
      margin-bottom: 90px;
    }
    @include responsive(wide) {
      font-size: 100px;
      margin-bottom: 90px;
    }
  }
  &-section {
    &__wrapper {
      padding: 0 (69px/3);
      @include responsive(tablet) {
        padding: 0 110px;
      }
      @include responsive(wide) {
        padding: 0;
        width: percentage(520px/1570px);
        margin-right: percentage(175px/1570px);
        display: inline-block;
        vertical-align: top;
      }
    }
    &__title {
      font-family: $opensans-font;
      font-size: (54px/3);
      font-weight: bold;
      margin-bottom: (60px/3);
      @include responsive(tablet) {
        font-size: 30px;
        margin-bottom: 25px;
      }
      @include responsive(wide) {
        font-size: 30px;
        margin-bottom: 17px;
      }
    }
    &__description {
      font-size: (33px/3);
      letter-spacing: -0.01em;
      line-height: (56 / 33);
      color: #525258;
      @include responsive(tablet) {
        font-size: 14px;
      }
      @include responsive(wide) {
        font-size: 16px;
      }
    }
    &__footer {
      margin-top: (90px/3);
      @include responsive(tablet) {
        margin-top: 35px;
      }
      @include responsive(wide) {
        margin-top: 35px;
      }
    }
    &__button {
      display: block;
      text-align: center;
      width: (440px/3);
      padding: (40px/3) 0;
      font-family: $maven-font;
      font-weight: 400;
      font-size: (36px/3);
      position: relative;
      border: 1px solid #34343a;
      @include responsive(tablet) {
        font-weight: bold;
        font-size: 15px;
        padding: 18px 0;
        width: 175px;
      }
      @include responsive(wide) {
        font-size: 15px;
        padding: 18px 0;
        width: 175px;
      }
      @include transition(300ms);
      &:active,
      &:hover {
        @include responsive(tablet) {
          color: #fff;
          &:after {
            width: 100%;
          }
        }
      }
      &:after {
        width: 0;
        z-index: -1;
        @include transition(300ms);
        height: 100%;
        background-color: #34343a;
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
      }
    }
    & ~ & {
      margin-top: (140px/3);
      @include responsive(tablet) {
        margin-top: 75px;
      }
      @include responsive(wide) {
        margin-top: 115px;
      }
    }
  }
  &-map {
    margin-top: (190px/3);
    padding-top: 48%;
    @include responsive(tablet) {
      margin-top: 80px;
    }
    @include responsive(wide) {
      margin-top: 55px;
    }
  }
  &-infomation {
    margin-top: (235px/3);
    font-size: 0;

    &__container {
      padding: 0 (69px/3);
      @include clearfix;
      @include responsive(tablet) {
        padding: 0 110px;
      }
      @include responsive(wide) {
        padding: 0;
      }
    }


    @include responsive(tablet) {
      margin-top: 145px;
    }
    @include responsive(wide) {
      margin-top: 70px;
    }
    @include responsive(wide) {
      margin-top: 0;
      width: 55%;
      display: inline-block;
      vertical-align: top;
    }
    &__item {
      display: table;
      width: 100%;
      table-layout: fixed;
      font-size: (33px/3);
      line-height: (56 / 33);
      color: #4a4a4c;
      &-wrapper {
        @include responsive(wide) {
          width: percentage(350px/875px);
          float: left;
          & ~ & {
            width: percentage(525px/875px);
          }
        }
      }
      &-title {
        font-family: $opensans-font;
        letter-spacing: 0.01em;
        width: percentage(300px/800px);
        vertical-align: top;
        font-weight: bold;
        display: table-cell;
        @include responsive(tablet) {
          font-size: 16px;
        }
        @include responsive(wide) {
          width: 145px;
          font-size: 16px;
        }
      }
      &-content {
        letter-spacing: -0.01em;
        vertical-align: top;
        display: table-cell;
        @include responsive(tablet) {
          font-size: 14px;
        }
      }
      &-wrapper ~ &-wrapper,
      & ~ & {
        margin-top: (55px/3);
        @include responsive(wide) {
          margin-top: 27px;
        }
      }
      @include responsive(wide) {
        &-wrapper ~ &-wrapper {
          margin-top: 0;
        }
      }
    }
  }
}