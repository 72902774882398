@import "../setting";
.layout-logo {
  width: (80px/3);
  height: (80px/3);
  display: block;
  position: absolute;
  left: (36px/3);
  top: (36px/3);
  z-index: 100;
  background-repeat: no-repeat;
  background-image: url(../images/logo.png);
  background-image: none, url(../images/logo-dndlab.svg);
  background-size: 100% 100%;
  [data-style="red"] & {
    background-image: url(../images/logo-white.png);
    background-image: none, url(../images/logo-dndlab-white.svg);
  }
  @include responsive(tablet) {
    width: 45px;
    height: 45px;
    left: 40px;
    top: 40px;
  }
  @include responsive(desktop) {
    position: fixed;
    left: 60px;
    top: 60px;
  }
  @include responsive(wide) {
    left: 40px;
    top: 40px;
  }
}

.layout-close {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  z-index: 100;
  &:before,
  &:after {
    width: 3px;
    height: 200%;
    background-color: $red-color;
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @include responsive(tablet) {
    right: 30px;
    top: 30px;
  }
  @include responsive(desktop) {
    position: fixed;
  }
}

.layout-gnb {
  width: 25px;
  height: 16px;
  position: absolute;
  right: (35px/3);
  top: (50px/3);
  z-index: 100;
  background-image: url(../images/hamburger.png);
  background-image: none, url(../images/hamburger.svg);
  background-size: 100% 100%;
  transition: all 300ms;
  [data-style="red"] & {
    background-image: url(../images/hamburger-white.png);
    background-image: none, url(../images/hamburger-white.svg);
  }
  @include responsive(tablet) {
    right: 40px;
    top: 50px;
    width: 35px;
    height: 23px;
  }
  @include responsive(desktop) {
    position: fixed;
    right: 60px;
    top: 70px;
  }
  @include responsive(wide) {
    right: 40px;
    top: 50px;
  }
  display: none;
  .lt-ie11 & {
    display: block;
  }
}

.layout-menu-toggle {
  display: block;
  z-index: 100;
  .lt-ie11 & {
    display: none;
  }
}

.hamburger {

  width: 26px;
  height: 26px;

  cursor: pointer;
  display: block;

  // Position
  position: absolute;
  right: 12px;
  top: 12px;
  @include responsive(tablet) {
    right: 40px;
    top: 50px;
    width: 33px;
    height: 33px;
  }
  @include responsive(desktop) {
    position: fixed;
    right: 60px;
    top: 70px;
  }
  @include responsive(wide) {
    right: 40px;
    top: 50px;
  }



  &-toggle {
    position: absolute;
    opacity: 0;
  }
  &-button {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    transform: scaleX(-1);
  }
  &-button:hover &-button__top,
  &-button:hover &-button__center,
  &-button:hover &-button__bottom {
    will-change: transform, width, margin, left;
  }
  &-button:hover &-button__top {
    width: 100%;
  }
  &-toggle:checked ~ &-button &-button__center {
    left: 50%;
    width: 0;
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  &-toggle:checked ~ &-button &-button__top,
  &-toggle:checked ~ &-button &-button__bottom {
    width: 110%;
    left: 50%;
    margin: 0;
  }
  &-toggle:checked ~ &-button  &-button__top {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &-toggle:checked ~ &-button &-button__bottom {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &-button {
    &__center,
    &__bottom,
    &__top {
      transition: all 300ms;
      position: absolute;
      height: 2px;
      @include responsive(tablet) {
        height: 3px;
      }
      width: 100%;
      background-color: $red-color;
      [data-style="red"] & {
        background-color: #ffffff;
      }
      content: '';
      left: 0%;
      display: block;
      border-radius: 5px;
      top: 50%;
      transform: translate(0, -50%);
    }
    &__center {
      width: 42%;
    }
    &__top {
      width: 77%;
      margin-top: -7px;
      @include responsive(tablet) {
        margin-top: -10px;
      }
    }
    &__bottom {
      width: 100%;
      margin-top: 7px;
      @include responsive(tablet) {
        margin-top: 10px;
      }
    }
  }
}