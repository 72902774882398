/*
 */
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 1024px;
$wide-width: 1600px;

$responsive-type: (
  //'mobile': '(min-width: #{$mobile-width})',
  //'only-mobile': '(min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px})',
  'only-mobile': '(max-width: #{$tablet-width - 1px})',
  'tablet': '(min-width: #{$tablet-width})',
  'only-tablet': '(min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px})',
  'desktop': '(min-width: #{$desktop-width})',
  'only-desktop': '(min-width: #{$desktop-width}) and (max-width: #{$wide-width - 1px})',
  'wide': '(min-width: #{$wide-width})'
);

@mixin responsive ($type) {
  $media-query: map-get($responsive-type, $type);
  @if ($media-query) {
    @media all and #{$media-query} {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin retina () {
  @media screen and (min-resolution: 2dppx) {
    @content;
  }
}