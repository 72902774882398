/*
 * Color Setting
 */
$font-color: #343132;
$point-color: #9c9792;
$error-color: #c33;
$red-color: #e91b1f;

/*
 * Font Setting
 */
$nanum-font: sans-serif;
$body-font-family: 'Spoqa Han Sans',
$nanum-font;
$english-font: 'Arial',
$body-font-family;
$roboto-font: 'Roboto',
$body-font-family;
$maven-font: 'Maven Pro',
$body-font-family;
$opensans-font: 'Open Sans',
$body-font-family;
/*
 * Layout Setting
 */
$layout-width: 1024px;
/*
 * Body Font Setting
 */

$body-font-size: 12px;
$body-font-color: $font-color;