.accessibility {
  @include inline-block;
  @include opacity(0);
  width: 1px;
  height: 1px;
  font-size: 1px;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  text-indent: -9999px;
  overflow: hidden;
}
.download-icon {
  width: 6px;
  height: 9px;
  background-image: url(../images/download-icon18x27.png);
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
  @include responsive(tablet) {
    width: 9px;
    height: 13px;
  }
}
.vm {
  vertical-align: middle;
}
.active {
  color: $red-color;
}