.main-container {
  height: 100%;
  position: relative;
  //overflow: hidden;
}
.main-scroller,
.main-page {
  position: relative;
  height: 100%;
  transform: translate(0,0);
  transition: all 1s;
}
.main-page {
  opacity: 1;
  transition: all 1s;
  overflow-y: scroll;
  & ~ & {
    opacity: 0;
  }
}
.main-scroller {
  &--animate {
    will-change: transform;
  }
  &--active {
    transform: translate(0,-100%);
    .main-page {
      opacity: 0;
    }
    .main-page ~ .main-page {
      opacity: 1;
    }
  }
}