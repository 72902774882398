$white: #fff;
.scroll-down {
  text-align: center;
  font-size: 15px;
  display: inline-block;
  [data-style="red"] & {
    color: $white;
  }
  &:before {
    display: block;
    content: attr(title);
    font-family: $opensans-font;
    font-size: 14px;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }
  &__button {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 500ms;
    background-clip: padding-box;
    &:before {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background-color: #656565;
      background-image: linear-gradient(to right, #656565 68%,#656565 68%,#cfcfcf 69%);
      transform: rotate(-55deg);
      transition: all 500ms;
      content: '';
      border-radius: 50%;
      [data-style="red"] & {
        background-color: #fff;
        background-image: linear-gradient(to right, $white 68%,$white 68%,#f1474b 69%);
      }
    }
    &:after {
      position: absolute;
      width: 68px;
      height: 68px;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-color: $white;
      display: block;
      content: '';
      transition: all 500ms;
      [data-style="red"] & {
        background-color: $red-color;
      }
    }
    &:hover {
      will-change: transform;
      transform: scale(1.15,1.15);
      &:before {
        transform: rotate(305deg);
      }
    }
    @keyframes mouse-moving {
      to {
        transform: translateY(5px);
      }
      from {
        transform: translateY(-5px);
      }
    }
    &-icon {
      position: absolute;
      width: 18px;
      height: 30px;
      border: 1px solid #656565;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 18px;
      z-index: 10;
      [data-style="red"] & {
        border-color: $white;
      }
      animation: mouse-moving 1s infinite linear alternate;
      &:after {
        display: block;
        width: 1px;
        height: 5px;
        background-color: #656565;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 4px;
        content: '';
        [data-style="red"] & {
          background-color: $white;
        }
      }
    }
  }
}