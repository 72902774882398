@mixin opacity($value) {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value * 100})";
  /* IE 5-7 */
  filter: alpha(opacity=#{$value * 100});
  /* Netscape */
  -moz-opacity: $value;
  /* Safari 1.x */
  -khtml-opacity: $value;
  /* Good browsers */
  opacity: $value;
}

@mixin clearfix() {
  *zoom: 1;
  &:after,
  &:before {
    display: table;
    content: '';
    clear: both;
  }
}

@mixin box-sizing($value: border-box) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin box-shadow($inset, $hoff, $voff, $blur, $color: null) {
  -webkit-box-shadow: $inset $hoff $voff $blur $color;
  -moz-box-shadow: $inset $hoff $voff $blur $color;
  box-shadow: $inset $hoff $voff $blur $color;
}

@mixin transition($time, $type: all) {
  -webkit-transition: $type $time cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: $type $time cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: $type $time cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: $type $time cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: $type $time cubic-bezier(0.645, 0.045, 0.355, 1);
}

@mixin transform($script, $origin: null) {
  -webkit-transform: $script;
  -moz-transform: $script;
  -ms-transform: $script;
  -o-transform: $script;
  transform: $script;
  @if ($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -o-transform-origin: $origin;
    transform-origin: $origin;
  }
}

// border-radius
@mixin border-radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin inline-block() {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin sprite-svg ($x: 0, $y: 0) {
  background-image: url(../images/icon-sprite.png);
  background-image: url(../images/icon-sprite.svg),none;
  background-repeat: no-repeat;
  background-size: rem(55 * 3) rem(45 * 3);
  background-position: $x $y;
}

@mixin position-center () {
  position: absolute;
  left: 50%;
  top: 50%;
  @include transform(translate(-50%, -50%));
}