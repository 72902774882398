html {
	// Mobile Font Size
	font-size: 10px;
	font-size: (10/320*100)+vmin;
	@include responsive(tablet) {
		font-size: (10/768*100)+vmin;
	}
	@include responsive(desktop) {
		font-size: 10px;
	}
}
body {
	font-size: 12px;
	-webkit-text-size-adjust: none;
}